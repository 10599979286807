import reserveGrayIcon from '../../assets/svg/reservation-gray.svg'
import paymentGrayIcon from '../../assets/svg/payment-gray.svg'
import documentGrayIcon from '../../assets/svg/document-gray.svg'
import FaqIcon from '../../assets/images/faq.png'
import HeroImg from '../../assets/images/buyers-guide-banner.webp'

export const Assets = {
  HeroImg,
}

export const GuidesDefaultValues = [
  {
    icon: reserveGrayIcon,
    title: 'How to Reserve',
    label: '',
    articles: [],
    url: '/buyers-guide/',
  },
  {
    icon: documentGrayIcon,
    title: 'Documentary Requirements',
    label: '',
    articles: [],
    url: '/buyers-guide/',
  },
  {
    icon: paymentGrayIcon,
    title: 'Payment Channel Options',
    label: '',
    articles: [],
    url: '/buyers-guide/',
  },
  {
    icon: FaqIcon,
    title: 'Frequently Asked Questions',
    label: '',
    articles: [],
    url: '/buyers-guide/frequently-asked-questions/',
  },
]
