import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'
import _ from 'lodash'
import Layout from '../../layouts/Layout/Layout'
import './BuyersGuide.scss'

import Seo from '../../components/Seo/Seo'
import { Assets, GuidesDefaultValues } from './BuyersGuide.utils'

import {
  PropertyTypes,
  MainPropTypes,
  AllContainersType,
} from './BuyersGuide.types'

const PropertyLayout = loadable(
  () => import('../../layouts/PropertyLayout/PropertyLayout'),
)

const Accordion = loadable(() => import('./components/Accordion'))
const ButtonsContainer = loadable(() => import('./components/ButtonsContainer'))

const BuyersGuide = ({
  data: {
    buyersGuideData,
    allProperty: { nodes: allProperty },
  },
  pageContext: { setFAQActive },
}: MainPropTypes): JSX.Element => {
  const [activeTabBtn, setActiveTabBtn] = useState<number>(setFAQActive || 0)
  const [featuredProperty] = useState<PropertyTypes>(
    allProperty[Math.floor(Math.random() * allProperty.length)],
  )

  const [isGuides, setIsGuides] = useState<any>(GuidesDefaultValues)

  const getBuyersGuideBanner = (): string | undefined => {
    const images = buyersGuideData?.contents.filter(
      (content) => content.file !== null,
    )

    return images.length ? images[0].file : undefined
  }

  const AccordionType = (values: any): void => {
    return values?.articles.map(({ header, content }: any) => (
      <Accordion heading={header} paragraph={content} key={header} />
    ))
  }

  const filterAllContainersValues = (values: any): any => {
    return values.map((value: AllContainersType) => {
      if (value?.field_collections[0]?.children) {
        const articles = value?.field_collections[0]?.children.map(
          (article) => {
            return {
              header: article.fields[0]?.value,
              content: article.fields[1]?.value,
            }
          },
        )

        const label = _.startCase(
          value.field_collections[0].name.replace(/-/g, ' ').toLowerCase(),
        )

        const updateGuides = (index: number): any => {
          setIsGuides((prevGuides: any) => {
            const updatedGuides = [...prevGuides]
            updatedGuides[index] = {
              ...updatedGuides[index],
              label,
              articles,
            }
            return updatedGuides
          })
        }

        switch (label) {
          case 'Reservation Process':
            updateGuides(0)
            break
          case 'Documentary Requirements':
            updateGuides(1)
            break
          case 'Payment Channel':
            updateGuides(2)
            break
          default:
            updateGuides(3)
            break
        }

        return {
          label,
          values: articles,
        }
      }
      return null
    })
  }

  useEffect(() => {
    if (buyersGuideData?.allContainers) {
      filterAllContainersValues(buyersGuideData.allContainers)
    }
  }, [buyersGuideData])

  return (
    <Layout navButtonVariant="burger">
      <Seo
        title="Buyer's Guide | How to Reserve an Avida Property"
        ogMetaData={{
          title: "Buyer's Guide | How to Reserve an Avida Property",
          description: "Buyer's Guide: How to and requirements.",
          image: getBuyersGuideBanner() || Assets?.HeroImg,
        }}
        jsonData={{
          '@type': 'Article',
          description: "Buyer's Guide: How to and requirements.",
          keywords:
            "properties, condo, philippines, house and lot, avidaland, avida, apartment, buyer's guide, how to,  buy, reserve",
          url: `${process.env.GATSBY_SITE_URL}/buyers-guide/`,
        }}
      />
      <h1 className="seo-hidden">How to Reserve an Avida Property</h1>
      <PropertyLayout
        property={featuredProperty}
        displayNavigation={false}
        pageName="buyers-guide"
      >
        <div className="buyers-guide">
          <LazyLoadImage
            className="buyers-guide-banner"
            src={getBuyersGuideBanner() || Assets?.HeroImg}
            alt="banner image"
          />
          <div className="buyers-guide-content-container">
            <h3 className="buyers-guide-sub-title">Buyer&apos;s Guide</h3>

            <ButtonsContainer
              activeTabBtn={activeTabBtn}
              setActiveTabBtn={setActiveTabBtn}
              isGuides={isGuides}
            />

            <div className="buyers-guide-main-content">
              {isGuides && activeTabBtn !== 3 ? (
                isGuides[activeTabBtn]?.articles.map(
                  ({ header, content }: any) => (
                    <div key={header}>
                      <h1>{header}</h1>
                      <p>{ReactHtmlParser(content)}</p>
                    </div>
                  ),
                )
              ) : (
                <div className="buyers-guide-contents">
                  {AccordionType(isGuides[activeTabBtn])}
                </div>
              )}
            </div>
          </div>
        </div>
      </PropertyLayout>
    </Layout>
  )
}

export default BuyersGuide

export const pageQuery = graphql`
  query {
    buyersGuideData: buyersGuide {
      ...BuyersGuidePageArticleFields
    }
    allProperty(filter: { propertyName: { ne: "home_page_banner" } }) {
      nodes {
        ...BuyersGuidePagePropertyFields
      }
    }
  }
`
